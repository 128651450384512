<template>
  <div class="max-vertical-height">
    <breadcrumbs title="Cart Overview"
      :crumbs="[
        { name: store.name, route: 'Store', params: { link: store.link } },
        { name: 'Cart', route: 'Cart', params: { link: store.link } }]">
    </breadcrumbs>
    <div class="main_content">
      <div class="section page-section">
        <div class="container">
          <div class="row" v-if="cart.length === 0">
            <div class="col-lg-12" style="text-align: center;">
              <h5>Your cart is empty.</h5>
            </div>
          </div>
          <div class="row" v-if="cart.length > 0">
            <div class="col-12">
              <div class="table-responsive shop_cart_table">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="product-thumbnail">&nbsp;</th>
                      <th class="product-name" style="text-align: center">Product</th>
                      <th class="product-student">Student</th>
                      <th class="product-price">Price</th>
                      <th class="product-quantity">Quantity</th>
                      <th class="product-subtotal">Total</th>
                      <th class="product-remove">Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, idx) in cart" :key="idx">
                      <td class="product-thumbnail">
                          <img v-if="item.ad" :src="item.ad.uriPreview" :alt="`product${idx}`">
                          <img v-else :src="productImageUri(item.product)" :alt="`product${idx}`">
                      </td>
                      <td data-title="Product">
                          {{ item.product.name }}
                        <p v-if="item.personalized" class="item-message-p" style="line-height: 18px;">
                          <strong>
                            {{ item.product.typePersonalization | personalizationTypeFilter }}
                          </strong>
                          <br>
                          <strong>
                            {{ item.student.personalizedName || `${item.student.firstName} ${item.student.lastName}` }}
                          </strong>
                        </p>
                        <p v-if="item.message" class="item-message-p"><strong style="white-space: pre;">{{ item.message }}</strong></p>
                      </td>
                      <td class="product-student" data-title="Student">
                        <router-link :to="{ name: 'Product', params: { link: item.storeLink, id: item.product.id }}">
                          <span v-if="item.donated">Donated</span>
                          <span v-else>{{ item.student.firstName }} {{ item.student.lastName }}</span>
                        </router-link>
                      </td>
                      <td class="product-price" data-title="Price">${{ item.itemPrice.toFixed(2) }}</td>
                      <td class="product-quantity" data-title="Quantity">
                        <div class="quantity">
                          <input type="button" value="-" class="minus" @click="$store.dispatch('decreaseCartItemQuantity', idx)">
                          <input type="text" disabled name="quantity" v-model="item.quantity" title="Qty" class="qty" size="4">
                          <input type="button" value="+" class="plus" @click="$store.dispatch('increaseCartItemQuantity', idx)">
                        </div>
                      </td>
                      <td class="product-subtotal" data-title="Total">${{ (item.quantity * item.itemPrice).toFixed(2) }}</td>
                      <td class="product-remove" data-title="Remove">
                        <a @click="$store.dispatch('removeItemFromCart', idx)"><i class="ti-close"></i></a>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="7" class="px-0"></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div class="row" v-if="cart.length > 0">
            <div class="col-12">
              <div class="medium_divider"></div>
              <div class="divider center_icon"><i class="ti-shopping-cart-full"></i></div>
              <div class="medium_divider"></div>
            </div>
          </div>
          <div class="row" v-if="cart.length > 0">
            <div class="col-md-6 offset-md-6">
              <div class="border p-3 p-md-4">
                <div class="heading_s1 mb-3">
                  <h6>Summary</h6>
                </div>
                <div class="table-responsive">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td class="cart_total_label">Subtotal</td>
                        <td class="cart_total_amount"><strong>${{ rawCartTotal.toFixed(2) }}</strong></td>
                      </tr>
                      <tr v-if="shipsToHome">
                        <td class="cart_total_label">Shipping</td>
                        <td class="cart_total_amount">${{ shipping.cost.toFixed(2) }} <small>{{ shipping.calculation }}</small></td>
                      </tr>
                      <template v-if="account.parentId === '87PNV2'">
                        <tr v-if="store.bParentsPayConvenienceFee && rawCartTotal > 0 && totalFee > 0">
                          <td class="cart_total_label">Convenience fee</td>
                          <td class="cart_total_amount">${{ totalFee.toFixed(2) }}</td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr v-if="store.bParentsPayConvenienceFee && rawCartTotal > 0 && convenienceFee > 0">
                          <td class="cart_total_label">Convenience fee</td>
                          <td class="cart_total_amount">${{ convenienceFee.toFixed(2) }}</td>
                        </tr>
                        <tr v-if="store.bParentsPayConvenienceFee && rawCartTotal > 0 && dedicationAdFee > 0">
                          <td class="cart_total_label">Ad fee</td>
                          <td class="cart_total_amount">${{ dedicationAdFee.toFixed(2) }}</td>
                        </tr>
                      </template>
                      <tr v-if="store.bCollectSalesTax && salesTaxAmount > 0 && rawCartTotal > 0">
                        <td class="cart_total_label">Sales tax</td>
                        <td class="cart_total_amount">${{ salesTaxAmount.toFixed(2) }}</td>
                      </tr>
                      <tr>
                        <td class="cart_total_label">Total</td>
                        <td class="cart_total_amount"><strong>${{ cartTotal.toFixed(2) }}</strong></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <router-link :to="{ name: 'Checkout' }" class="btn btn-fill-out">
                  Proceed to Checkout
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.item-message-p {
  font-size: 12px;
  margin: 1px;
}
</style>

<script>
import { mapGetters }       from 'vuex'
import { storeCommonMixin } from '@/mixins/storeCommon'
import Breadcrumbs          from '@/components/layout/header/Breadcrumbs'

export default {
  name: 'Cart',
  mixins: [storeCommonMixin],
  components: { Breadcrumbs },
  computed: {
    ...mapGetters([
      'cart',
      'cartTotal',
      'rawCartTotal',
      'shipping',
      'shipsToHome',
      'store',
      'account',
      'totalFee',
      'salesTaxAmount',
      'convenienceFee',
      'dedicationAdFee'
    ])
  },
  created () {
    this.$store.dispatch('setDiscount', null)
  }
}
</script>
